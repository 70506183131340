<script setup>
    const { $api, $me } = useNuxtApp();

    // Initialize i18n
    const localePath = useLocalePath();

    // Emits
    const emit = defineEmits([
        'track-click',
    ]);

    const accountUrl = computed(() => {
        if ($me.isBuyer()) {
            return localePath('account');
        }

        if ($me.isTransporter()) {
            return localePath('transports');
        }

        if ($me.isLogistician()) {
            return localePath('orders');
        }

        return `${backendUrl()}/profile/me`;
    });

    // Logout from the app
    const logout = () => {
        $api.post('logout', {
            onSuccess: () => {
                window.location.reload();
            }
        });
    };
</script>

<template>
    <div>
        <RegisterBuyerButton
            v-show="! $me.check()"
            name="header"
            :label="$t('Register')"
            @click="emit('track-click', 'register')"
        />

        <NuxtLink
            v-show="! $me.check()"
            :to="localePath('login')"
        >
            <PrimevueButton
                :label="$t('Login')"
                severity="secondary"
                @click="emit('track-click', 'login')"
            />
        </NuxtLink>

        <NuxtLink
            v-show="$me.check() && $me.isValidated()"
            :to="accountUrl"
        >
            <PrimevueButton :label="$t('My account')" class="white-space-nowrap" />
        </NuxtLink>

        <PrimevueButton
            v-show=" $me.check()"
            :label="$t('Logout')"
            severity="danger"
            @click="logout"
        />
    </div>
</template>
